const users = [
  {
    id: 1,
    name: "Leanne Graham",
    username: "Bret",
    G: "F",
  },
  {
    id: 2,
    name: "Ervin Howell",
    username: "Antonette",
    G: "M",
  },
  {
    id: 3,
    name: "Clementine Bauch",
    username: "Samantha",
    G: "F",
  },
  {
    id: 4,
    name: "Patricia Lebsack",
    username: "Karianne",
    G: "F",
  },
  {
    id: 5,
    name: "Chelsey Dietrich",
    username: "Kamren",
    G: "F",
  },
  {
    id: 6,
    name: "Mrs. Dennis Schulist",
    username: "Leopoldo_Corkery",
    G: "F",
  },
  {
    id: 7,
    name: "Kurtis Weissnat",
    username: "Elwyn.Skiles",
    G: "M",
  },
  {
    id: 8,
    name: "Nicholas Runolfsdottir V",
    username: "Maxime_Nienow",
    G: "M",
  },
  {
    id: 9,
    name: "Glenna Reichert",
    username: "Delphine",
    G: "F",
  },
  {
    id: 10,
    name: "Clementina DuBuque",
    username: "Moriah.Stanton",
    G: "F",
  },
  {
    id: 11,
    name: "Donald Duck",
    username: "donald.duck",
    G: "M",
  },
  {
    id: 12,
    name: "Adam Savage",
    username: "adam.is.savage",
    G: "M",
  },
  {
    id: 13,
    name: "Daniel Redhill",
    username: "Harry.trotter",
    G: "M",
  },
  {
    id: 14,
    name: "Jones",
    username: "Indiana Jones",
    G: "M",
  },
  {
    id: 15,
    name: "Claudia",
    username: "Claudia_Beckam",
    G: "F",
  },
  {
    id: 16,
    name: "Fabio Edwards",
    username: "Fabio Edwards",
    G: "M",
  },
  {
    id: 17,
    name: "Jonah Roads",
    username: "Jonah_Roads_",
    G: "M",
  },
  {
    id: 18,
    name: "Damian Knight",
    username: "The Rook",
    G: "M",
  },
  {
    id: 19,
    name: "Rowan Atkinson",
    username: "Mr Bean",
    G: "M",
  },
  {
    id: 20,
    name: "Bugs Bunny",
    username: "Albuquerque",
    G: "F",
  },
   {
    id: 21,
    name: "ʜɪʟᴀʟ ᴍᴏʜᴅ",
    username: "Hilalmohd_",
    G: "M",
  },
  {
    id: 22,
    name: "florencia",
    username: "Floriffo_",
    G: "F",
  },
  {
    id: 23,
    name: "pilar",
    username: "Pilicena_",
    G: "F",
  },
  {
    id: 24,
    name: "Gianfranco",
    username: "Gian.pelizza",
    G: "M",
  },
  {
    id: 25,
    name: "Mauro",
    username: "Mauroalcarajo",
    G: "M",
  },
  {
    id: 26,
    name: "Fede Lemhöfer.",
    username: "Federico_lemhofer",
    G: "M",
  },
  {
    id: 27,
    name: "Benjamin",
    username: "Monito_benjamin23",
    G: "M",
  },
  {
    id: 28,
    name: "Nicholas Runolfsdottir V",
    username: "Maxime_Nienow",
    G: "M",
  },
  {
    id: 29,
    name: "JHON FLOREZ",
    username: "Jhon_florez92",
    G: "F",
  },
  {
    id: 30,
    name: "Susannah Makram",
    username: "susannAhmakram",
    G: "F",
  },
  {
    id: 31,
    name: "Jacques Kadjo",
    username: "Jamse_smith_15",
    G: "M",
  },
  {
    id: 32,
    name: "albrian",
    username: "albrian_marani",
    G: "M",
  },
  {
    id: 33,
    name: "Josefina Romero",
    username: "josefina.romero.20",
    G: "F",
  },
  {
    id: 34,
    name: "Indiana Jones",
    username: "Indy Shot First",
    G: "M",
  },
  {
    id: 35,
    name: "marcelo sicu",
    username: "_marcelo_sicu_",
    G: "M",
  },
  {
    id: 36,
    name: "Angel Rupanjel Maya",
    username: "rupanjel.maya",
    G: "F",
  },
  {
    id: 37,
    name: "setarhe",
    username: "se.ta2639",
    G: "F",
  },
  {
    id: 38,
    name: "tassopoulou",
    username: "tassopoulou.elene",
    G: "F",
  },
  {
    id: 39,
    name: "sha__dow",
    username: "sha__dow86",
    G: "F",
  },
  {
    id: 40,
    name: "jeon faria",
    username: "jeon_faria_",
    G: "F",
  },
];

export default users;
